import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen, faFolder, faInfoCircle, faUser, faHome, faHashtagLock, faQrcode, faBars, faChevronCircleLeft, faSignature, faComments, faMoneyCheckEdit, faMobileAlt } from '@fortawesome/pro-solid-svg-icons';
import PoweredByBox from '../PoweredByBox';
import { useAuth } from '../../lib/hooks/extData/auth';
import { useAccountant } from '../../lib/hooks/extData/accountant';
import { Link } from 'react-router-dom';
import AccountantLogo from '../AccountantLogo';
import { useLinkedClients } from '../../lib/hooks/extData/clients';
import BoxedCircularProgress from '../BoxedCircularProgress';
import { ClientStatus, ClientType } from '../../lib/dataDefinitions/client';
import { useSettings } from '../../lib/hooks/extData/settings';
import { useSigningRequest } from '../../lib/hooks/extData/signingRequest';
import { useFormRequest } from '../../lib/hooks/extData/formRequest';

const DRAWER_WIDTH = 250;
const SMALL_SCREEN_WIDTH_THRESHOLD = 820;

const LoggedInGrid: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { branding: accBranding } = useAccountant();
  const { loggedInUser, loggedInClientRS, authApi } = useAuth();
  const { linkedClientsRS, linkedClientsApi } = useLinkedClients();
  const { settingsApi } = useSettings();
  const { pendingCountSummaryPerOwnerRS, signingRequestApi } = useSigningRequest();
  const { unfinishedCountSummaryPerClientRS, formRequestApi } = useFormRequest();

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })

  const logOut = useCallback(() => {
    authApi.logOut();
  }, [authApi]);

  const toggleDrawer = useCallback(() => {
    setIsMobileDrawerOpen(!isMobileDrawerOpen);
  }, [isMobileDrawerOpen, setIsMobileDrawerOpen]);

  useEffect(() => {
    if (loggedInClientRS.errorCode === 401) {
      // Invalid token for all operations - 401 Unauthorized
      // Clear tokens and proceed to logon screen
      logOut();
    }
  }, [logOut, loggedInClientRS])

  // Fetch all initial info
  useEffect(() => {
    if (!loggedInUser) return;
    authApi.getLoggedInClient(loggedInUser.client_id);
    linkedClientsApi.getLinkedClients();
    settingsApi.getGeneralSettings();
    signingRequestApi.getPendingCountSummaryPerOwner();
    formRequestApi.getUnfinishedCountSummaryPerClient();
  }, [loggedInUser, linkedClientsApi, authApi, settingsApi, signingRequestApi, formRequestApi]);

  // Handle resizing
  useEffect(() => {
    if (dimensions.width <= SMALL_SCREEN_WIDTH_THRESHOLD) {
      setIsMobileDrawerOpen(false);
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  }, [dimensions])

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    };

    window.addEventListener('resize', handleResize)
  }, []);

  // For user menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const listItemIconSx = {
    minWidth: 30
  }

  const drawerContent = <>
    <Box>
      <Toolbar sx={{ justifyContent: 'center' }}>
        {!accBranding
          ? ''
          : <Box>{accBranding.Logo
            ? <Box sx={{ my: 2 }}><AccountantLogo style={{ maxWidth: .8 * DRAWER_WIDTH }} /></Box>
            : <Typography variant="h5" color="primary" textAlign="center" sx={{ my: 2 }}>{accBranding.Name}</Typography>
          }</Box>}
      </Toolbar>
      <List>
        <Link to={'/'}>
          <ListItemButton>
            <ListItemIcon sx={listItemIconSx}>
              <FontAwesomeIcon icon={faHome} />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </Link>
        <Link to={'/contactUs'}>
          <ListItemButton>
            <ListItemIcon sx={listItemIconSx}>
              <FontAwesomeIcon icon={faComments} />
            </ListItemIcon>
            <ListItemText primary="Contact us" />
          </ListItemButton>
        </Link>
      </List>
      {linkedClientsRS.pending
        ? <BoxedCircularProgress align="center" mt={2} />
        : linkedClientsRS.error
          ? <Typography color="error" textAlign="center">{linkedClientsRS.error}</Typography>
          : linkedClientsRS.data?.map((linkedClient) => (
            <React.Fragment key={linkedClient.id}>
              <Divider />
              <List
                subheader={
                  <ListSubheader sx={{ lineHeight: 1.5, mt: 2, mb: 1, color: '#222' }}>{linkedClient.name}{linkedClient.company_status !== ClientStatus.ACTIVE ? ` (${linkedClient.company_status})` : ''}</ListSubheader>
                }
              >
                <Link to={'/client/' + linkedClient.id}>
                  <ListItemButton>
                    <ListItemIcon sx={listItemIconSx}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </ListItemIcon>
                    <ListItemText primary="Client details" />
                  </ListItemButton>
                </Link>
                {linkedClient.type !== ClientType.CON && <>
                  <Link to={'/files/' + linkedClient.id}>
                    <ListItemButton>
                      <ListItemIcon sx={listItemIconSx}>
                        <FontAwesomeIcon icon={faFolder} />
                      </ListItemIcon>
                      <ListItemText primary="Files" />
                    </ListItemButton>
                  </Link>
                  <Link to={'/signingRequests/' + linkedClient.id}>
                    <ListItemButton>
                      <ListItemIcon sx={listItemIconSx}>
                        <FontAwesomeIcon icon={faSignature} />
                      </ListItemIcon>
                      <ListItemText primary="Signing requests" />
                      <Chip
                        sx={{ fontWeight: 'bold' }}
                        label={pendingCountSummaryPerOwnerRS.data?.[linkedClient.id] || 0}
                        color={(pendingCountSummaryPerOwnerRS.data?.[linkedClient.id] || 0) > 0 ? 'error' : 'default'} size="small" />
                    </ListItemButton>
                  </Link>
                </>}
                <Link to={'/formRequests/' + linkedClient.id}>
                  <ListItemButton>
                    <ListItemIcon sx={listItemIconSx}>
                      <FontAwesomeIcon icon={faMoneyCheckEdit} />
                    </ListItemIcon>
                    <ListItemText primary="Forms" />
                    <Chip
                      sx={{ fontWeight: 'bold' }}
                      label={unfinishedCountSummaryPerClientRS.data?.[linkedClient.id] || 0}
                      color={(unfinishedCountSummaryPerClientRS.data?.[linkedClient.id] || 0) > 0 ? 'error' : 'default'} size="small" />
                  </ListItemButton>
                </Link>
              </List>
            </React.Fragment>
          ))}
    </Box>
    <Divider />
    <Box mt={5}>
      <PoweredByBox />
    </Box>
  </>

  const userTopInfo = <Box sx={{ textAlign: 'left', m: isSmallScreen ? 2 : 0 }}>
    <Typography variant="body1">{loggedInClientRS.data?.preferred_name || loggedInClientRS.data?.first_name} {loggedInClientRS.data?.last_name}</Typography>
    <Typography variant="body2" textTransform='none'>{loggedInClientRS.data?.email}</Typography>
  </Box>

  return <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <AppBar
      position="fixed"
      sx={{ width: isSmallScreen ? '100%' : `calc(100% - ${DRAWER_WIDTH}px)`, ml: `${DRAWER_WIDTH}px` }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Stack direction="row" alignItems="center">
          {isSmallScreen
            && <IconButton
              color="inherit"
              aria-label="open menu"
              onClick={toggleDrawer}
              edge="start"
              sx={{ mr: 2 }}
            >
              <FontAwesomeIcon icon={faBars} />
            </IconButton>}
          <Typography variant="h6" noWrap component="div">
            CLIENT PORTAL
          </Typography>
        </Stack>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Tooltip title="Click for options">
            <Button
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              color="inherit"
              variant="text"
            >
              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Avatar sx={{ mr: 1 }}><FontAwesomeIcon icon={faUser} /></Avatar>
                {!isSmallScreen && userTopInfo}
              </Box>
            </Button>
          </Tooltip>
        </Box>
        <Menu
          id="account-menu"
          anchorEl={anchorEl}
          open={open}
          onClick={handleClose}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {/* <MenuItem onClick={handleClose}><FontAwesomeIcon icon={faComment} /><Typography sx={{ ml: 1 }}>Contact preferences</Typography></MenuItem> */}
          {isSmallScreen
            && <Box>
              {userTopInfo}
              <Divider sx={{ mb: 1 }} />
            </Box>}
          <Link to="/account">
            <MenuItem><FontAwesomeIcon icon={faHashtagLock} /><Typography sx={{ ml: 1 }}>Change password</Typography></MenuItem>
          </Link>
          <Link to="/setUp2FA">
            <MenuItem><FontAwesomeIcon icon={faQrcode} /><Typography sx={{ ml: 1 }}>Set up Two-Factor Authentication</Typography></MenuItem>
          </Link>
          <Link to="/linkMobileApp">
            <MenuItem><FontAwesomeIcon icon={faMobileAlt} /><Typography sx={{ ml: 1 }}>Link our mobile app</Typography></MenuItem>
          </Link>
          <MenuItem onClick={logOut}><FontAwesomeIcon icon={faDoorOpen} /><Typography sx={{ ml: 1 }}>Log out</Typography></MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>

    {isSmallScreen
      ? <Drawer
        sx={{
          width: '100%',
          maxWidth: DRAWER_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '100%',
            maxWidth: DRAWER_WIDTH,
            boxSizing: 'border-box',
          },
        }}
        variant="temporary"
        open={isMobileDrawerOpen}
        anchor="left"
        onClick={toggleDrawer}
      >
        <List>
          <ListItemButton>
            <ListItemIcon>
              <FontAwesomeIcon icon={faChevronCircleLeft} />
            </ListItemIcon>
            <ListItemText primary="hide menu" />
          </ListItemButton>
        </List>
        {drawerContent}
      </Drawer>
      : <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        {drawerContent}
      </Drawer>}
    <Box
      component="main"
      sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, maxWidth: '100%' }}
    >
      <Toolbar />
      <Box>
        {/* window: {dimensions.width} x {dimensions.height} */}
        {loggedInClientRS.error
          ? <Alert severity="error">{loggedInClientRS.errorCode === 401 ? "Your session has ended. Log out and back in" : loggedInClientRS.error}</Alert>
          : children}
      </Box>
    </Box>
  </Box>
}

export default LoggedInGrid;