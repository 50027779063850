import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FsActualElemType, FsElem, FsElemOrigin, iconClasses } from "../../lib/dataDefinitions/fsElem";
import { faFile, faFolder } from "@fortawesome/pro-solid-svg-icons";

const FsElemIcon: React.FC<{ elem: FsElem }> = ({ elem }) => <FontAwesomeIcon icon={
    elem.fsElemOrigin === FsElemOrigin.TPL || elem.type === FsActualElemType.DIR
        ? faFolder
        : (iconClasses[elem.content_type] || iconClasses[elem.content_type.split('/')[0]] || faFile)
} />

export default FsElemIcon;