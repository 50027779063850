import { faChevronLeft, faEye, faUpload } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import { ClientStatus } from '../../lib/dataDefinitions/client'
import { FsElemOrigin, FsActualElemType, Breadcrumb, FsElem } from '../../lib/dataDefinitions/fsElem'
import FsElemIcon from './FsElemIcon'

const FileBrowserGrid: React.FC<{
    breadcrumbs: Breadcrumb[],
    folderChildren: FsElem[],
    ownerClientStatus?: ClientStatus,
    goToFolder: (id: number | null, fsElemOrigin: FsElemOrigin) => void,
    openElemDetails: (fsElemOrigin: FsElemOrigin, id: number) => void,
}> = ({
    breadcrumbs, folderChildren, ownerClientStatus,
    goToFolder, openElemDetails,
}) => {
        return (
            <Grid container spacing={2}>
                {breadcrumbs.length > 1 && <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <Paper sx={{ p: 2, textAlign: 'center', cursor: 'pointer' }} onClick={() => goToFolder(breadcrumbs[breadcrumbs.length - 2].id, breadcrumbs[breadcrumbs.length - 2].idType)}>
                        <Typography variant="h3" color="#AAA"><FontAwesomeIcon icon={faChevronLeft} /></Typography>
                        <Typography variant="body1" color="initial">BACK</Typography>
                        <Typography variant="body2" sx={{ visibility: 'hidden' }}>placeholder</Typography>
                    </Paper>
                </Grid>}
                {folderChildren.map((elem) => <Grid key={elem.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <Paper
                        sx={{ p: 2, textAlign: 'center', cursor: 'pointer' }}
                        onClick={() => elem.fsElemOrigin === FsElemOrigin.TPL || elem.type === FsActualElemType.DIR
                            ? goToFolder(elem.id, elem.fsElemOrigin || FsElemOrigin.ACT)
                            : openElemDetails(elem.fsElemOrigin || FsElemOrigin.ACT, elem.id)}>
                        <Typography variant="h3" color="#AAA">
                            <FsElemIcon elem={elem} />
                        </Typography>
                        <Typography variant="body1" color="initial" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 'calc(100vw - 80px)' }}>{elem.name}</Typography>
                        <Typography variant="body2" color={elem.client_perms === '100' || ownerClientStatus !== ClientStatus.ACTIVE ? 'gray' : 'green'}
                            sx={{ visibility: elem.fsElemOrigin === FsElemOrigin.TPL || elem.type === FsActualElemType.DIR ? 'visible' : 'hidden' }}>
                            <FontAwesomeIcon icon={faEye} style={{ marginRight: 5 }} />
                            {elem.client_perms !== '100' && ownerClientStatus === ClientStatus.ACTIVE && <FontAwesomeIcon icon={faUpload} style={{ marginRight: 5 }} />}
                            {elem.client_perms === '100' || ownerClientStatus !== ClientStatus.ACTIVE ? 'Read-only' : 'Read & upload'}
                        </Typography>
                    </Paper>
                </Grid>)}
            </Grid>
        )
    }

export default FileBrowserGrid