import { faChevronLeft, faEye, faMinus, faUpload } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ClientStatus } from '../../lib/dataDefinitions/client'
import { Breadcrumb, formatSize, FsActualElemType, FsElem, FsElemOrigin } from '../../lib/dataDefinitions/fsElem'
import FsElemIcon from './FsElemIcon'
import { FormattedDate } from 'react-intl'
import styled from '@emotion/styled'

type OrderableKey = 'name' | 'upload_date' | 'size_bytes';
function isOfTypeOrderableKey(keyInput: string): keyInput is OrderableKey {
    return ['name', 'upload_date', 'size_bytes'].includes(keyInput);
}

interface OrderableColumn {
    varKey: OrderableKey
    label: string
    widthPx?: number
}

const getSortedFolderChildren = (elems: FsElem[], keyToOrder: OrderableKey, shouldOrderAsc: boolean) => {
    const directionCoef = shouldOrderAsc ? 1 : -1;
    return [...elems].sort((elemA, elemB) => {
        const valA = elemA[keyToOrder], valB = elemB[keyToOrder];
        if (valA !== null && valB !== null) { // Neither A nor B null
            return (valA > valB ? 1 : -1) * directionCoef;
        }
        else if (valA === null && valB !== null) return 1; // A null, B not null
        else if (valB === null && valA !== null) return -1; // B null, A not null
        return 0;
    });
}

const columns: OrderableColumn[] = [
    {
        varKey: 'name',
        label: 'Name',
    },
    {
        varKey: 'upload_date',
        label: 'Upload date',
        widthPx: 160,
    },
    {
        varKey: 'size_bytes',
        label: 'Size',
        widthPx: 120,
    },
]

const StyledTableRow = styled(TableRow)(() => ({
    '&': {
        cursor: 'pointer'
    },
    '&:hover': {
        backgroundColor: '#EEEEEE',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const IconTableCell = styled(TableCell)(() => ({
    '&': {
        fontSize: '1.2em',
        textAlign: 'right',
        color: "#AAA",
    },
}));

const FileBrowserList: React.FC<{
    breadcrumbs: Breadcrumb[],
    folderChildren: FsElem[],
    ownerClientStatus?: ClientStatus,
    goToFolder: (id: number | null, fsElemOrigin: FsElemOrigin) => void,
    openElemDetails: (fsElemOrigin: FsElemOrigin, id: number) => void,
}> = ({
    breadcrumbs, folderChildren, ownerClientStatus,
    goToFolder, openElemDetails,
}) => {
        const [orderedFolderChildren, setOrderedFolderChildren] = useState<FsElem[]>([]);
        const [orderingKey, setOrderingKey] = useState<OrderableKey | null>(null);
        const [isOrderingAsc, setIsOrderingAsc] = useState<boolean>(true);

        const toggleSorting = (column: OrderableColumn) => {
            if (orderingKey === column.varKey) {
                // toggle direction on the current column
                const newIsOrderingAsc = !isOrderingAsc;
                setIsOrderingAsc(newIsOrderingAsc);
                localStorage.setItem('Files_FileBrowserList_isOrderingAsc', newIsOrderingAsc.toString());
            } else {
                // preserve direction from the previously sorted column
                setOrderingKey(column.varKey);
                localStorage.setItem('Files_FileBrowserList_orderingKey', column.varKey);
            }
        }

        useEffect(() => {
            if (!orderingKey) return;
            setOrderedFolderChildren(
                getSortedFolderChildren(folderChildren, orderingKey, isOrderingAsc)
            );
        }, [folderChildren, orderingKey, isOrderingAsc]);

        useEffect(() => {
            const savedOrderingKey = localStorage.getItem('Files_FileBrowserList_orderingKey');
            if (savedOrderingKey && isOfTypeOrderableKey(savedOrderingKey)) setOrderingKey(savedOrderingKey);
            else setOrderingKey('name');

            const savedIsOrderingAsc = localStorage.getItem('Files_FileBrowserList_isOrderingAsc');
            if (savedIsOrderingAsc) setIsOrderingAsc(savedIsOrderingAsc === 'true');
            // console.log("Getting saved ordering", savedOrderingKey, savedIsOrderingAsc)
        }, [])

        return (
            <TableContainer component={Box}>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="files and folders list" size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell width={'80px'}></TableCell>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.varKey}
                                    width={column.widthPx}>
                                    <TableSortLabel
                                        active={orderingKey === column.varKey}
                                        direction={isOrderingAsc ? 'asc' : 'desc'}
                                        onClick={_ => toggleSorting(column)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {breadcrumbs.length > 1
                            && <StyledTableRow
                                onClick={() => goToFolder(breadcrumbs[breadcrumbs.length - 2].id, breadcrumbs[breadcrumbs.length - 2].idType)}
                            >
                                <IconTableCell><FontAwesomeIcon icon={faChevronLeft} /></IconTableCell>
                                <TableCell colSpan={3}>BACK</TableCell>
                            </StyledTableRow>}
                        {orderedFolderChildren.map((elem) => (
                            <StyledTableRow
                                key={`${elem.fsElemOrigin}#${elem.id}`}
                                onClick={() => elem.fsElemOrigin === FsElemOrigin.TPL || elem.type === FsActualElemType.DIR
                                    ? goToFolder(elem.id, elem.fsElemOrigin || FsElemOrigin.ACT)
                                    : openElemDetails(elem.fsElemOrigin || FsElemOrigin.ACT, elem.id)}
                            >
                                <IconTableCell>
                                    <FsElemIcon elem={elem} />
                                </IconTableCell>
                                <TableCell sx={{ verticalAlign: 'middle' }}>
                                    <Typography variant="body2" color="initial" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 'calc(100vw - 80px)' }}>{elem.name}</Typography>
                                    {(elem.fsElemOrigin === FsElemOrigin.TPL || elem.type === FsActualElemType.DIR)
                                        && <Typography variant="caption" color={elem.client_perms === '100' || ownerClientStatus !== ClientStatus.ACTIVE ? 'gray' : 'green'}>
                                            <FontAwesomeIcon icon={faEye} style={{ marginRight: 5 }} />
                                            {elem.client_perms !== '100' && ownerClientStatus === ClientStatus.ACTIVE && <FontAwesomeIcon icon={faUpload} style={{ marginRight: 5 }} />}
                                            {elem.client_perms === '100' || ownerClientStatus !== ClientStatus.ACTIVE ? 'Read-only' : 'Read & upload'}
                                        </Typography>}
                                </TableCell>
                                <TableCell>{(elem.fsElemOrigin === FsElemOrigin.TPL || elem.type === FsActualElemType.DIR)
                                    ? <FontAwesomeIcon icon={faMinus} style={{ marginRight: 5 }} />
                                    : elem.upload_date
                                        ? <FormattedDate value={elem.upload_date} dateStyle="short" timeStyle="short" />
                                        : <Chip label="not uploaded" color='error' size='small' />
                                }</TableCell>
                                <TableCell>{(elem.fsElemOrigin === FsElemOrigin.TPL || elem.type === FsActualElemType.DIR)
                                    ? <FontAwesomeIcon icon={faMinus} style={{ marginRight: 5 }} />
                                    : formatSize(elem.size_bytes)}</TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

export default FileBrowserList